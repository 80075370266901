import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ContactForm from '@features/ContactForm';
import { Strings, StringsWrapper, SEO } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import SectionHero from './sections/SectionHero';
import SectionClients from './sections/SectionClients';
import SectionCaseStudies from './sections/SectionCaseStudies';
import SectionAdvisory from './sections/SectionAdvisory';
import SectionAbout from './sections/SectionAbout';
import {
  IllustrationDevServices,
  IllustrationRagnarsonFund,
  ContactFormHeading,
  SectionFund,
  SectionDevServices,
} from './Home.styled';

const linkList = [
  {
    label: 'Purpose',
    title: 'Go to content',
    to: '/',
  },
  {
    label: 'Transparency',
    title: 'Go to content',
    to: '/',
  },
  {
    label: 'Team',
    title: 'Go to content',
    to: '/',
  },
];

const Home = () => {
  const {
    site: {
      siteMetadata: { title, description },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  return (
    <>
      <SEO title={title} description={description} />
      <SectionHero />
      <SectionClients />
      <StringsWrapper>
        {useStateScreenMobile() ? (
          <Strings name="home-case-study--mobile" height={369} mobile />
        ) : (
          <Strings name="home-case-study" top={-200} height={582} />
        )}
        <SectionCaseStudies />
      </StringsWrapper>

      <StringsWrapper>
        {useStateScreenMobile() ? (
          <Strings name="home-dev-services--mobile" height={466} mobile />
        ) : (
          <Strings name="home-dev-services" top={-110} height={601} />
        )}
        <SectionDevServices
          left={<IllustrationDevServices />}
          template={{
            headline: 'Software Development',
            teaser: `We have built software of all shapes and sizes for over fifty companies.
              Whether you’re a start-up or a scale-up, we’re here to help you.`,
            cta: { label: 'Learn more', to: '/software-development' },
          }}
        />
      </StringsWrapper>

      <StringsWrapper>
        {useStateScreenMobile() ? (
          <>
            <Strings name="home-advisory--mobile" top={280} height={237} mobile />
            <Strings name="home-fund--mobile" top={894} height={224} mobile />
          </>
        ) : (
          <Strings name="home-advisory-fund" top={20} height={836} />
        )}
        <SectionAdvisory />
        <SectionFund
          left={<IllustrationRagnarsonFund />}
          template={{
            headline: 'Ragnarson Fund',
            teaser: 'We invest in early-stage impact-driven startups.',
            cta: { label: 'Investment thesis', to: '/fund' },
          }}
        />
      </StringsWrapper>
      <StringsWrapper>
        {useStateScreenMobile() ? (
          /* <Strings name="home-about--mobile" top={178} left={26} height={164} mobile /> Not used in V1 */
          <Strings name="home-about--v1" top={80} height={118} mobile /> /* Version for V1, without list items */
        ) : (
          /* <Strings name="home-about" top={144} height={397} /> Version with dots, for About section with list items  */
          <Strings name="home-about--v1" top={-120} height={472} /> /* Version for V1, without list items */
        )}
        <SectionAbout linkList={linkList} />
      </StringsWrapper>
      <ContactForm headline={<ContactFormHeading>Tell us how we can help you change the world</ContactFormHeading>} />
    </>
  );
};

export default Home;
