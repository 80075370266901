import React from 'react';
import { SectionAboutHeader } from './SectionAbout.styled';

const SectionAbout = () => (
  <SectionAboutHeader
    right={<div />}
    template={{
      headline: 'About',
      teaser: `Ragnarson is a purpose-driven organisation. We care about our values.
    We believe in transparency and empowering the team.`,
    }}
  />
);

export default SectionAbout;
