import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CardCase, SectionContent } from '@fragments';
import { getLogo } from '@helpers/methods';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { Waypoint } from 'react-waypoint';
import { CaseStudiesContent, CaseStudiesAbout, CardContainer } from './SectionCaseStudies.styled';

const CaseStudiesAboutContent = () => (
  <SectionContent
    content={{
      headline: '15yr track record',
      teaser: `Our customers collectively raised over €83M in funding, made five exits,
        and some became leaders in their markets.`,
      cta: {
        label: 'See case studies',
        to: '/case-studies',
      },
    }}
  />
);

const SectionCaseStudies = () => {
  const {
    allCaseStudiesJson: { nodes },
  } = useStaticQuery(graphql`
    query {
      allCaseStudiesJson(filter: { meta: { featured: { eq: true } } }) {
        nodes {
          id
          meta {
            slug
            company
            thumbnail {
              body
              svgId
              title
            }
          }
        }
      }
    }
  `);

  const caseStudiesList = nodes.map((card) => {
    const {
      id,
      meta: { thumbnail, slug, company },
    } = card;
    const slugCompany = company.replace(/\s/g, '-').toLowerCase();
    const LogoComponent = getLogo(thumbnail?.svgId);

    return (
      <CardCase
        key={id}
        className="keen-slider__slide"
        to={`/case-studies/${slug || slugCompany}`}
        logo={
          (
            <LogoComponent
              config={{
                widthMb: 200,
                width: 250,
              }}
            />
          ) ||
          thumbnail?.svgId ||
          'ruby'
        }
        title={thumbnail?.title || 'Dummy title'}
        body={thumbnail?.body || 'Dummy body'}
        size="small"
      />
    );
  });

  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 2,
    mode: 'free',
    dragSpeed: 1,
    duration: 650,
    spacing: 16,
  });

  const [firstEntered, setFirstEntered] = useState(false);

  const onWaypointAreaEnter = () => {
    if (firstEntered) return;
    slider.moveToSlide(0.5);
    setFirstEntered(true);
  };

  useEffect(() => {
    if (!firstEntered) return undefined;
    const wayPointTimer = setTimeout(() => slider.moveToSlide(0), 650);
    return () => clearTimeout(wayPointTimer);
  }, [firstEntered]);

  return (
    <>
      <CaseStudiesContent>
        <CaseStudiesAbout>
          <CaseStudiesAboutContent />
        </CaseStudiesAbout>

        <CardContainer ref={sliderRef} className="keen-slider">
          {caseStudiesList}
        </CardContainer>
      </CaseStudiesContent>
      <Waypoint onEnter={() => onWaypointAreaEnter()} />
    </>
  );
};

export default SectionCaseStudies;
