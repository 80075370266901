import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from 'styled-components';
import { spaceDt, spaceMb, getTypography, color, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container, TextEffect } from '@fragments';
import { VikingHero } from '@illustrations';

const SectionHeroBase = styled(Container)`
  ${screenMin('lg')} {
    flex-grow: 1;
    flex-direction: row-reverse;
  }
`;

const SectionHeroColumn = styled.div`
  ${screenMin('lg')} {
    min-height: 100%;
    flex: 0 0 50%;
  }
`;

const SectionHeroContent = styled(SectionHeroColumn)`
  display: flex;
  flex-direction: column;

  .line-break {
    display: none;
  }

  ${screenMin('lg')} {
    padding-left: ${spaceDt(2)};
  }

  ${screenMin('xl')} {
    .line-break {
      display: inline-block;
    }
  }
`;

const SectionHeaderLink = styled.button`
  text-decoration: none;
  color: ${color.text.primary};
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
`;

const SectionHeroContentHeader = styled.h1`
  margin-top: ${spaceMb(2.5)};
  margin-bottom: ${spaceMb(1)};

  ${getTypography('heading-1')}

  ${screenMin('sm')} {
    margin-bottom: ${spaceMb(4)};
  }

  ${screenMin('lg')} {
    margin-top: ${spaceDt(9)};
    margin-bottom: ${spaceDt(6)};
  }

  ${screenMin('xl')} {
    margin-bottom: ${spaceDt(12)};
  }
`;

const SectionHeroContentSubheader = styled.h2`
  margin-bottom: ${spaceMb(1)};
  margin-left: ${spaceMb(2)};
  color: ${color.text.secondary};

  ${getTypography('heading-4')}

  ${screenMin('lg')} {
    margin-top: auto;
    margin-bottom: ${spaceDt(1)};
    margin-left: 0;
  }
`;

const SectionHeroPicture = styled(VikingHero)`
  max-width: 100%;
  width: ${toVW(330, 'mobile')};
  height: ${toVW(250, 'mobile')};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(5.5)};
    width: ${toVW(625, 'desktop')};
    height: ${toVW(476, 'desktop')};
  }
`;

const scrollToFooter = () => scrollTo('#the-footer');

const SectionHero = () => (
  <SectionHeroBase>
    <SectionHeroColumn>
      <SectionHeroPicture />
    </SectionHeroColumn>

    <SectionHeroContent>
      <SectionHeroContentHeader>
        We are the Tech <br className="line-break" /> team <TextEffect effect="highlight">behind</TextEffect>
        the <br className="line-break" /> success of top <br className="line-break" /> European Startups
      </SectionHeroContentHeader>

      <SectionHeroContentSubheader>
        Contact us in our offices in
        <TextEffect effect="text-primary">
          <SectionHeaderLink onClick={scrollToFooter}> Berlin </SectionHeaderLink>
        </TextEffect>
        or{' '}
        <TextEffect effect="text-primary">
          <SectionHeaderLink onClick={scrollToFooter}>Łódź.</SectionHeaderLink>
        </TextEffect>
      </SectionHeroContentSubheader>
    </SectionHeroContent>
  </SectionHeroBase>
);

export default SectionHero;
