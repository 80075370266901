import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';

const CaseStudiesAbout = styled.div`
  flex-shrink: 0;
  padding-left: var(--content-padding-left);
  padding-right: var(--content-padding-right);
  margin-bottom: ${spaceMb(4)};

  ${screenMin('lg')} {
    padding-left: ${spaceDt(2)};
    padding-right: 0;
    max-width: ${toVW(540, 'desktop')};
    margin-right: ${spaceDt(22)};
  }
`;

const CardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-right: ${spaceMb(1)};
  cursor: ${(props) => (props.grabbing ? 'grabbing' : 'grab')};

  ${screenMin('lg')} {
    padding-right: ${spaceDt(1)};
  }
`;

const CaseStudiesContent = styled(Container)`
  display: flex;
  padding-right: 0;
  ${screenMin('lg')} {
    flex-direction: row;
  }
`;

export { CaseStudiesContent, CaseStudiesAbout, CardContainer };
