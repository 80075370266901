import styled from 'styled-components';
import { Section } from '@fragments';
import { screenMax, screenMin, spaceDt } from '@helpers/styles';

export const SectionAdvisoryBase = styled(Section)`
  ${screenMax('lg')} {
    flex-direction: column-reverse;
  }

  ${screenMin('lg')} {
    margin-top: ${spaceDt(20)};
  }
`;
