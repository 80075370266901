import React from 'react';
import { SectionAdvisoryBase } from './SectionAdvisory.styled';
import { IllustrationAdvisory } from '../Home.styled';

const SectionAdvisory = () => (
  <SectionAdvisoryBase
    right={<IllustrationAdvisory />}
    template={{
      headline: 'Advisory',
      teaser: 'We advise our customers on hiring developers, remote work, and transparent, decentralised management.',
      cta: { label: 'Learn more', to: '/advisory' },
    }}
  />
);

export default SectionAdvisory;
