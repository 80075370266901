import styled from 'styled-components';
import { Section } from '@fragments';
import { toVW } from '@helpers/methods';
import { spaceMb, spaceDt, screenMin, getTypography } from '@helpers/styles';
import { VikingDiamond, VikingHand, VikingDrakkar } from '@illustrations';

const IllustrationDevServices = styled(VikingDiamond)`
  width: 100%;
  height: ${toVW(304, 'mobile')};

  ${screenMin('lg')} {
    height: ${toVW(454, 'desktop')};
  }
`;

const IllustrationAdvisory = styled(VikingDrakkar)`
  width: 100%;
  height: ${toVW(304, 'mobile')};

  ${screenMin('lg')} {
    height: ${toVW(488, 'desktop')};
  }
`;

const IllustrationRagnarsonFund = styled(VikingHand)`
  width: 100%;
  height: ${toVW(304, 'mobile')};

  ${screenMin('lg')} {
    height: ${toVW(426, 'desktop')};
  }
`;

const ContactFormHeading = styled.h3`
  margin-top: ${spaceMb(13.5)};
  margin-bottom: ${spaceMb(5)};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(13.5)};
    margin-bottom: ${spaceDt(5)};
  }

  ${getTypography('heading-3')}
`;

const SectionFund = styled(Section)`
  ${screenMin('lg')} {
    margin-top: ${spaceDt(20)};
  }
`;

const SectionDevServices = styled(Section)`
  ${screenMin('lg')} {
    margin-top: ${spaceDt(30)};
  }
`;

export {
  IllustrationDevServices,
  IllustrationAdvisory,
  IllustrationRagnarsonFund,
  ContactFormHeading,
  SectionFund,
  SectionDevServices,
};
