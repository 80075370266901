import React from 'react';
import { getLogo } from '@helpers/methods';
import { SectionClients, SectionClientsList } from './SectionClients.styled';

const CLIENT_LOGO_DIMENSIONS = {
  growthmentor: {
    width: 111,
  },
  bookingsync: {
    width: 130,
  },
  certace: {
    width: 83,
  },
  melodie: {
    width: 139,
  },
  vehiculum: {
    width: 140,
  },
};

const keysArray = Object.keys(CLIENT_LOGO_DIMENSIONS);

const ClientList = () =>
  keysArray.map((name, index) => {
    const LogoComponent = getLogo(name);
    const isLastItem = keysArray.length - 1 === index;

    return (
      <LogoComponent
        key={name}
        className={`section-clients-logo ${isLastItem && `section-clients-logo--last`}`}
        config={CLIENT_LOGO_DIMENSIONS[name]}
      />
    );
  });

const PageIndexSectionClients = () => (
  <SectionClients>
    <SectionClientsList>
      <ClientList />
    </SectionClientsList>
  </SectionClients>
);

export default PageIndexSectionClients;
