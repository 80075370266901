import styled from 'styled-components';
import { screenMin, spaceDt, spaceMb } from '@helpers/styles';
import { Scrollable } from '@fragments';

const SectionClients = styled(Scrollable)`
  margin-top: ${spaceMb(2)};
  margin-bottom: ${spaceMb(2)};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(11)};
    margin-bottom: ${spaceDt(11)};
  }

  .section-clients-logo {
    &:not(&--last) {
      margin-right: ${spaceMb(6)};

      ${screenMin('lg')} {
        margin-right: ${spaceDt(8)};
      }
    }
  }
`;

const SectionClientsList = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${screenMin('lg')} {
    margin-left: auto;
    margin-right: auto;
    max-width: 62vw;
  }
`;

export { SectionClients, SectionClientsList };
